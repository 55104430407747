@import '../../../global/scss/config';

.socket-debugger{

  @include pad-box(24px);
  @include max-size;
  background:$secondary-background-color;

  .actionable {
    @include max-size;
    margin-right:12px;
    .json-buttons{
      margin-bottom:12px;
      & > div {
        margin-right:12px;
        &:last-of-type{
          margin-right:0;
        }
      }
    }
  }

  .payload {
    @include pad-box(12px);
    @include border(solid);
    @include max-size;
    background: white;
    border-radius: 5px;
    overflow-y: scroll;
  }
}
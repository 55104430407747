@mixin animation($animate...) {
    $max: length($animate);
    $animations: '';

    @for $i from 1 through $max {
        $animations: #{$animations + nth($animate, $i)};

        @if $i < $max {
            $animations: #{$animations + ", "};
        }
    }
    -webkit-animation: $animations;
    -moz-animation:    $animations;
    -o-animation:      $animations;
    animation:         $animations;
}

@mixin keyframes($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }
    @-moz-keyframes #{$animationName} {
        @content;
    }
    @-o-keyframes #{$animationName} {
        @content;
    }
    @keyframes #{$animationName} {
        @content;
    }
}

@include keyframes(scale-from-zero-centered) {
  0%    { opacity:0; transform: translate(-50%, -50%) scale(0); }
  100%  { opacity:1; transform: translate(-50%, -50%) scale(1); }
}
@include keyframes(fade-in-from-bottom) {
  0%    { opacity:0; transform: translate(0, -10%) }
  100%  { opacity:1; transform: translate(0) }
}

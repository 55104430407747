/* Grays */

$dark-gray: #505050;
$medium-gray:#8a8a8a;
$light-gray:#bfbfbf;

/* Blues */

$light-blue:#5d84e4;
$light-blue-300: #cad9ff;

/* Pinks */

$pink: #ff2d55;
$light-pink: #f17d93;

/* Oranges */

$orange:#ef7a49;
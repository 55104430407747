@import '../../../global/scss/config';

.button {
  @include pad-box(9px 12px);
  @include font(14px, 700, 0.05em, white);
  @include box-shadow-1;
  text-align: center;
  background-color: $primary-button-bg-color;
  border-radius: 5px;
  transition: all 0.2s;

  &.enabled {
    &:hover {
      transform: translate(0, -3px);
      cursor: pointer;
    }
  }

  &.disabled {
    pointer-events: none;
    background-color: gray !important;
  }

}